import { RouteHandle } from '@asaprint/asap/interfaces.js';
import Link from '@engined/client/components/Link.js';
import { useLocale } from '@engined/client/contexts/LocaleContext.js';
import { NavigateNext } from '@mui/icons-material';
import { Breadcrumbs, Typography } from '@mui/material';
import React, { useMemo } from 'react';
import { useMatches, useParams } from 'react-router-dom';

const ConnectedBreadcrumbs: React.FC = () => {
  const { t } = useLocale();
  const params = useParams();
  const matches = useMatches();
  const breadcrumbs = useMemo(
    () =>
      matches
        .map((m) => (m.handle as RouteHandle)?.breadcrumbs)
        .flat()
        .filter(Boolean),
    [matches],
  );

  return (
    <>
      {breadcrumbs?.length > 0 && (
        <Breadcrumbs
          separator={<NavigateNext fontSize="small" />}
          aria-label="breadcrumb"
          sx={{ fontSize: (theme) => theme.typography.pxToRem(14) }}
        >
          {breadcrumbs.map((b, i) =>
            'node' in b ? (
              b.node
            ) : b.to ? (
              <Link key={i} color="inherit" to={typeof b.to === 'function' ? b.to(params) : b.to}>
                {typeof b.text === 'string' ? b.text : t(b.text)}
              </Link>
            ) : (
              <Typography key={i} variant="body2" color="textPrimary">
                {typeof b.text === 'string' ? b.text : t(b.text)}
              </Typography>
            ),
          )}
        </Breadcrumbs>
      )}
    </>
  );
};

export default React.memo(ConnectedBreadcrumbs);
