import React from 'react';
import { Link as MuiLink, LinkProps as MuiLinkProps } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

export type Props = MuiLinkProps<typeof RouterLink, { component?: typeof RouterLink }>;

const Link = React.forwardRef<HTMLAnchorElement, Props>((props, ref) => {
  return <MuiLink {...props} component={RouterLink} ref={ref} />;
});

Link.displayName = 'Link';

export default Link;
