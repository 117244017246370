import queryString from 'query-string';

export type ParseUrlParams<url> = url extends `${infer path}(${infer optionalPath})`
  ? ParseUrlParams<path> & Partial<ParseUrlParams<optionalPath>>
  : url extends `${infer start}/${infer rest}` // Slashes
  ? ParseUrlParams<start> & ParseUrlParams<rest>
  : url extends `${infer start}.${infer rest}` // Dots
  ? ParseUrlParams<start> & ParseUrlParams<rest>
  : url extends `:${infer param}`
  ? { readonly [k in param]: string }
  : unknown;

function invariant(cond: any, message: string): asserts cond {
  if (!cond) throw new Error(message);
}

export function generatePath<TPath extends string = string>(path: TPath, params: ParseUrlParams<TPath>): string {
  return path
    .replace(/:(\w+)/g, (_, key) => {
      invariant(params[key] != null, `Missing ":${key}" param`);
      return params[key]!;
    })
    .replace(/\/*\*$/, () => (params['*'] == null ? '' : params['*'].replace(/^\/*/, '/')));
}

export function url<TPath extends string = string>(
  path: TPath,
  params: ParseUrlParams<TPath>,
  query?: { [name: string]: string | number | string[] },
): string {
  let compiled = generatePath(path, params);

  if (query) {
    const qs = queryString.stringify(query);
    compiled = `${compiled}?${qs}`;
  }

  return compiled;
}
